import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueCookies from 'vue-cookies'
import Vue2Editor from 'vue2-editor';

Vue.config.productionTip = false;

Vue.prototype.shortDate = function(value) {
    return new Date(value).toLocaleDateString(this.userInfo.locale, { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'UTC' })
}

Vue.prototype.displayDate = function(value) {
    return new Date(value).toLocaleDateString(this.userInfo.locale, { day: '2-digit', month: 'short', year: 'numeric', timeZone: 'UTC' })
}

Vue.prototype.formatDate = function(value) {
    return new Date(value).toLocaleDateString(this.userInfo.locale, { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' })
}

Vue.prototype.longDateTime = function(value) {
    return new Date(value).toLocaleDateString(this.userInfo.locale, { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: this.userInfo.zoneinfo })
}

Vue.prototype.formatCurrency = function(currency = "USD", value = 0) {
    return new Intl.NumberFormat(this.userInfo.locale, { style: 'currency', currency: currency, }).format(value);
}

Vue.prototype.formatPercent = function(value = 0) {
    return new Intl.NumberFormat(this.userInfo.locale, { style: 'percent', 'minimumFractionDigits': 2, 'maximumFractionDigits': 2,}).format(value / 100);
}

Vue.prototype.openFile = function(response, filename) {
    const href = new Blob([response.data], { type: response.data.type });
    const element = document.createElement('a');
    element.setAttribute('href', window.URL.createObjectURL(href));
    element.setAttribute('download', filename || 'download');
    element.style.display = 'none';
    element.click();
    element.remove();
    setTimeout(() => {
        window.URL.revokeObjectURL(element.href);
    }, 0);
}

Vue.use(vuetify);
Vue.use(VueCookies);
Vue.use(Vue2Editor);

new Vue({
    router,
    vuetify,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')