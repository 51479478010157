<template>
    <v-app>
        <Appbar v-if="!$route.meta.hideAppbar" @update="updateDrawer"></Appbar>
        <Navbar v-if="!$route.meta.hideNavbar" :drawer="drawer"></Navbar>
        <v-main style="overflow-y: hidden; position: absolute; top: 0; right: 0; bottom: 0; left: 0; scrollbar-color: dark;">
            <router-view></router-view>
        </v-main>
        <v-overlay :value="this.$store.state.loading" opacity="0.3" z-index=500>
            <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
        </v-overlay>
    </v-app>
</template>
<script>
import Appbar from "@/components/Appbar.vue";
import Navbar from "@/components/Navbar.vue";
export default {
    name: 'App',
    components: {
        Appbar,
        Navbar,
    },
    data() {
        return {
            drawer: true
        }
    },
    computed: {
        showTerms() {
            return this.$store.state.userInfo;
        },
    },
    mounted() {
        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        this.setTheme(darkModeMediaQuery);
        darkModeMediaQuery.onchange = () => {
            this.setTheme(darkModeMediaQuery);
        }
    },
    beforeCreate() {
        this.$store.commit('initializeStore');
    },
    methods: {
        setTheme(darkModeMediaQuery) {
            const darkMode = JSON.parse(this.$cookies.get('app.darkMode')) ? true : darkModeMediaQuery.matches;
            this.$vuetify.theme.dark = darkMode;
        },
        refreshToken() {
            this.$store.dispatch('refreshToken').catch(() => {
                this.$router.push("/signin?page=" + this.$router.history.current.path);
            })
        },
        updateDrawer() {
            this.drawer = !this.drawer;
            if (window.innerWidth < 1264 && !this.drawer) {
                setTimeout(() => {
                    this.drawer = !this.drawer;
                }, 100);
            }
        }
    },
};
</script>

<style>
html {
    overflow-y: hidden !important;
    
}
</style>