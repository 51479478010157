<template>
    <v-navigation-drawer v-if="authorized" absolute app v-model="drawerStatus" style="z-index:6;">
        <v-list two-line class="text-center">
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ userInfo.organization_name }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item-avatar rounded size="80" class="rounded-circle border-primary">
                <v-img :src=userInfo.photos.picture></v-img>
            </v-list-item-avatar>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ userInfo.display_name }}
                        <div class="caption font-weight-bold">
                            {{ currentUserType }}
                        </div>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{ userInfo.username }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <div class="pa-3 transparent text-no-wrap rounded-pill">
                        <a class="text-decoration-none" href="/profile">
                            <v-chip color="primary" style="cursor:pointer">
                                {{ $t('manageProfile') }}
                            </v-chip>
                        </a>
                    </div>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
        </v-list>
        <v-list dense nav shaped>
            <v-list-item v-for="(item, i) in modules" :key="i" :to="item.href" link>
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <template v-slot:append>
            <v-divider></v-divider>
            <div class="pb-2 pt-2">
                <v-btn name="signOut" block plain @click="logout">
                    {{ $t('signOut') }}
                </v-btn>
            </div>
        </template>
    </v-navigation-drawer>
</template>
<script>
export default {
    name: "Modules",
    props: ["drawer"],
    data() {
        return {
            drawerStatus: this.drawer
        }
    },
    watch: {
        drawer(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.drawerStatus = newValue;
            }
        },
    },
    computed: {
        authorized() {
            return this.$store.state.authorized;
        },
        userInfo() {
            return this.$store.state.userInfo;
        },
        modules() {
            return this.$store.state.metadata.modules;
        },
        currentUserType() {
            const user = this.$store.state.metadata?.userTypes?.find(user => user.type === this.$store.state.userInfo.type);
            if (user) {
                return user.name;
            } else {
                return '';
            }
        },
    },
    methods: {
        logout() {
            this.$store.dispatch('logout').finally(() => {
                localStorage.clear();
                this.$router.push('/signin');
            });
        },
    }
}
</script>
<style scoped>
.border-primary {
    border: 2px solid #2196f3;
}
</style>
<i18n>
  {
    "en": {
      "manageProfile" : "Manage my profile",
      "signOut" : "Sign Out"
    }
  }
  </i18n>