import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import Cookies from "vue-cookies";
import router from "@/router";

Vue.use(Vuex);

const oauth = axios.create({
  baseURL: process.env.VUE_APP_OAUTH_ENDPOINT,
});

const api = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      store.state.authenticated = false;
      store.state.authorized = false;
      store.state.token = null;
      store.state.metadata = null;
      store.state.loading = false;
      store.state.data = false;
      store.state.project = null;
      store.state.member = null;
      store.state.token = {};
      store.state.userInfo = {};
      store.state.notifications = [];
      store.state.recent = null;
      store.state.favorites = null;
      store.state.connections = null;
      store.state.invitations = null;
      store.state.projectMetrics = null;
      store.state.consultingPartners = null;
      store.state.openRoles = null;
      store.state.organization = null;
      store.state.projects = [];
      store.state.forums = [];
      store.state.files = null;
      store.state.dashboards = null;
      store.state.teamMembers = [];
      store.state.organizationMembers = [];
      store.state.organizationResourceManagers = [];
      store.state.errorMessage = null;
      return Promise.reject(error);
    } else if (403 === error.response.status) {
      if (
        error.response.data.error &&
        error.response.data.error === "mfa_required"
      ) {
        return Promise.reject(error);
      } else if (error.response.config.method === 'post') {
        return Promise.reject(error);
      } else {
        router.push("/not-authorized");
      }
    } else if (404 === error.response.status) {
      //router.push("/not-found");
    } else if (500 === error.response.status) {
      router.push("/error");
    }
    return Promise.reject(error);
  }
);

const initialState = () => ({
  session: null,
  authenticated: false,
  authorized: false,
  loading: false,
  token: null,
  userInfo: null,
  data: null,
  registration: null,
  notifications: [],
  metadata: {},
  project: null,
  proposal: null,
  member: null,
  teamMembers: [],
  organizationMembers: [],
  organizationResourceManagers: [],
  projects: null,
  favorites: null,
  proposals: null,
  recent: null,
  suggested: null,
  openRoles: null,
  connections: null,
  invitations: null,
  projectMetrics: null,
  consultingPartners: [],
  softwareVendors: null,
  customers: [],
  files: null,
  candidate: null,
  organization: null,
  dashboards: null,
  errorMessage: null,
  success: false,
  error: false,
});

const store = new Vuex.Store({
  state: initialState(),

  getters: {
    administrator: (state) => {
      return state.userInfo.type == "ADMINISTRATOR";
    },
    getState: (state) => (stateCode) => {
      return stateCode != null
        ? state.metadata.states.find(
            (element) => element.code == this.form.stateCode
          ).state
        : null;
    },
    getCountry: (state) => (countryCode) => {
      return countryCode != null
        ? state.metadata.countries.find(
            (element) => element.code == countryCode
          ).country
        : null;
    },
    getHeaders: (state) => {
      return {
        headers: {
          "Authorization": state.token ? state.token.token_type + " " + state.token.access_token : "",
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      };
    },
  },
  actions: {
    async signUp({ commit }, payload) {
      commit("setLoading", true);
      const json = JSON.stringify({
        companyName: payload.companyName,
        website: payload.website,
        preferredName: payload.preferredName,
        email: payload.email,
        title: payload.title,
      });
      return new Promise((resolve, reject) => {
        api
          .post("registrations", json, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    fetchRegistration({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api
          .get("registrations/" + payload.id, {
            headers: {
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setRegistration", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    updateRegistration({ commit }, payload) {
      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        api
          .patch(
            "registrations/" + payload.id,
            JSON.stringify(payload.patchRequest),
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    signIn({ commit }, payload) {
        return new Promise((resolve, reject) => {
            oauth.post("oauth2/token", "username=" + payload.username 
                + "&password=" + encodeURIComponent(payload.password) 
                + "&grant_type=password&client_id=" + process.env.VUE_APP_CLIENT_ID 
                + "&client_secret=" + encodeURIComponent(process.env.VUE_APP_CLIENT_SECRET), {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                },
            }).then((response) => {
                Cookies.set("token", response.data, response.data.expires_in + "s", null, null, true, "Strict");
                resolve(response);
            }).catch((error) => {
                commit("setErrorMessage", error.response.data);
                reject(error);
            });
        });
    },
    submitChallenge({ commit }, payload) {
      return new Promise((resolve, reject) => {
        oauth
          .post(
            "oauth2/challenge", "username=" +
              payload.username +
              "&session=" +
              encodeURIComponent(payload.session) +
              "&mfa_token=" +
              encodeURIComponent(payload.mfa_token) +
              "&grant_type=mfa_otp",
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            Cookies.set(
              "token",
              response.data,
              response.data.expires_in + "s",
              null,
              null,
              true,
              "Strict"
            );
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    signInAs({ commit, state }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
            oauth.post("oauth2/token","subject=" + payload.subject + "&actor_token=" + state.token.access_token + "&refresh_token=" +
                encodeURIComponent(state.token.refresh_token) +
                "&client_id=" + process.env.VUE_APP_CLIENT_ID +
                "&client_secret=" + encodeURIComponent(process.env.VUE_APP_CLIENT_SECRET) + 
                "&grant_type=token_exchange", {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                }
            }).then((response) => {
                Cookies.set(
                    "token",
                    response.data,
                    state.token.expires_in + "s",
                    null,
                    null,
                    true,
                    "Strict"
                );
                resolve(response);
            }).catch((error) => {
                commit("setErrorMessage", error.response.data);
                reject(error);
            }).finally(() => {
                commit("setLoading", false);
            });
        });
    },
    refreshToken({ commit, state }) {
      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        oauth
          .post(
            "oauth2/token",
            "grant_type=refresh_token&refresh_token=" + state.token.refresh_token + 
            "&subject_token=" + state.token.access_token + 
            "&client_secret=" + encodeURIComponent(process.env.VUE_APP_CLIENT_SECRET) + 
            "&client_id=" + process.env.VUE_APP_CLIENT_ID,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            Cookies.set(
              "token",
              response.data,
              state.token.expires_in + "s",
              null,
              null,
              true,
              "Strict"
            );
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    logout({ commit, state }) {
      Cookies.remove("token");
      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        oauth
          .post(
            "oauth2/token/revoke",
            "token=" + encodeURIComponent(state.token.access_token),
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            commit("resetStore");
            commit("setLoading", false);
          });
      });
    },
    fetchUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        oauth
          .get("oauth2/userinfo", {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setUserInfo", response.data);
            resolve();
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    fetchMetadata({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .get("metadata", {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setMetadata", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    fetchNotifications({ commit, state }) {
      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        api
          .get("notifications", {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setNotifications", response.data);
            resolve();
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    actionNotification({ commit, state }, payload) {
      commit("setLoading", true);
      const json = JSON.stringify({
        action: payload.action,
      });
      return new Promise((resolve, reject) => {
        api
          .post("notifications/" + payload.id + "/action", json, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    fetchMyProfile({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit("setMember", null);
        commit("setErrorMessage", null);
        api
          .get("members/" + state.userInfo.subject, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setMember", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    getOrganization({ commit, state }, payload) {
      commit("setOrganization", null);
      return new Promise((resolve, reject) => {
        api
          .get("organizations/" + payload.id, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setOrganization", response.data);
            resolve();
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject();
          });
      });
    },
    getCandidate({ commit, state }, payload) {
      commit("setCandidate", null);
      return new Promise((resolve, reject) => {
        api
          .get("members/" + payload.id, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setCandidate", response.data);
            resolve();
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject();
          });
      });
    },
    fetchMember({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            api.get("members/" + payload.id, {
                headers: {
                    "Authorization": state.token.token_type + " " + state.token.access_token,
                    "Accept": "application/json",
                }
            }).then((response) => {
                commit("setMember", response.data);
                resolve(response);
            }).catch((error) => {
                commit("setErrorMessage", error.response.data);
                reject(error);
            });
        });
    },
    fetchTeamMembers({ commit, state }) {
        return new Promise((resolve, reject) => {
        commit("setTeamMembers", null);
        api
          .get("members/" + state.userInfo.subject + "/team-members", {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setTeamMembers", response.data);
            resolve();
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    fetchOrganizationMembers({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit("setOrganizationMembers", null);
        api
          .get(
            "organizations/" +
              state.userInfo.organization_id +
              "/members?type=SKILLED_PROFESSIONAL",
            {
              headers: {
                Authorization:
                  state.token.token_type + " " + state.token.access_token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            commit("setOrganizationMembers", response.data);
            resolve();
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    fetchOrganizationResourceManagers({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit("setOrganizationMembers", null);
        api
          .get(
            "organizations/" +
              state.userInfo.organization_id +
              "/members?type=RESOURCE_MANAGER",
            {
              headers: {
                Authorization:
                  state.token.token_type + " " + state.token.access_token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            commit("setOrganizationResourceManagers", response.data);
            resolve();
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    fetchOrganizationProjects({ commit, state }, payload) {
        commit("setLoading", true);
        return new Promise((resolve, reject) => {
            api.get(payload.endpoint,
                {
                    headers: {
                        "Authorization": state.token.token_type + " " + state.token.access_token,
                        "Accept": "application/json",
                    },
                }
            ).then((response) => {
                commit("setProjects", response.data);
                resolve(response);
            }).catch((error) => {
                reject(error);
            }).finally(() => {
                commit("setLoading", false);
            });
        });
    },
    fetchFavorites({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .get("members/" + state.userInfo.subject + "/favorites", {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setFavorites", response.data);
            resolve();
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    fetchRecent({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .get("members/" + state.userInfo.subject + "/recent", {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setRecent", response.data);
            resolve();
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    fetchSuggested({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .get("members", {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setSuggested", response.data);
            resolve();
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    // fetchPartnerMembers({ commit, state }, payload) {
    //   return new Promise((resolve, reject) => {
    //     api
    //       .get(
    //         "organizations/" + payload.id + "/members?type=" + payload.type,
    //         {
    //           headers: {
    //             Authorization:
    //               state.token.token_type + " " + state.token.access_token,
    //             Accept: "application/json",
    //           },
    //         }
    //       )
    //       .then((response) => {
    //         commit("setTeamMembers", response.data);
    //         resolve();
    //       })
    //       .catch((error) => {
    //         commit("setErrorMessage", error.response.data);
    //         reject(error);
    //       });
    //   });
    // },
    searchMarketplace({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        api
          .post("members/searches/marketplace", payload, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            commit("setSuggested", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    searchesTeam({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        api
          .post(
            "members/searches/team",
            { searchTerms: [payload.keywords] },
            {
              headers: {
                Authorization:
                  state.token.token_type + " " + state.token.access_token,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            commit("setTeamMembers", response.data);
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    searchesOrganization({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        api
          .post(
            "members/searches/organization",
            { searchTerms: [payload.keywords] },
            {
              headers: {
                Authorization:
                  state.token.token_type + " " + state.token.access_token,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            commit("setTeamMembers", response.data);
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    fetchOpenRoles({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .get("projects/roles", {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setOpenRoles", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    searchBySkills({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        api
          .post(
            "members/" + state.userInfo.subject + "/search/skills",
            payload,
            {
              headers: {
                Authorization:
                  state.token.token_type + " " + state.token.access_token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            commit("setSuggested", response.data);
            resolve();
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    searchByCompany({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        api
          .post(
            "members/" + state.userInfo.subject + "/search/keywords",
            payload,
            {
              headers: {
                Authorization:
                  state.token.token_type + " " + state.token.access_token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            commit("setSuggested", response.data);
            resolve();
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    fetchActiveProjects({ commit, state }) {
      commit("setProjects", null);
      commit("setErrorMessage", null);
      return new Promise((resolve, reject) => {
        api
          .get("projects", {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setProjects", response.data);
            resolve();
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    searchProjectsBySkills({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        api
          .get("projects?skills=" + payload.skills, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setProjects", response.data);
            resolve();
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    fetchPartners({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .get("organizations/partners", {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setConsultingPartners", response.data);
            resolve();
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    fetchSoftwareVendors({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .get("organizations/vendors", {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setSoftwareVendors", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    fetchCustomers({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .get("organizations/customers", {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setCustomers", response.data);
            resolve();
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    fetchProposals({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .get(
            "organizations/" + state.userInfo.organization_id + "/proposals",
            {
              headers: {
                Authorization:
                  state.token.token_type + " " + state.token.access_token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            commit("setProposals", response.data);
            resolve();
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    fetchProposal({ commit, state }, payload) {
      return new Promise(() => {
        commit("setLoading", true);
        api
          .get("proposals/" + payload.id, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setProposal", response.data);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    createProposal({ commit, state }, payload) {
      commit("setLoading", true);
      const json = JSON.stringify({
        projectId: payload.projectId,
        contactId: payload.contactId,
        summary: payload.summary,
        scope: payload.scope,
        approach: payload.approach,
        positions: payload.positions,
      });
      return new Promise((resolve, reject) => {
        api
          .post("proposals", json, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setProposal", response.data);
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },

    //
    // candiate for removeal with action being moved to the generic post
    //
    actionProposal({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true);
        const json = JSON.stringify({
          action: payload.action,
        });
        api
          .post("proposals/" + payload.id + "/action", json, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setProposal", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    
    updateProposal({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            commit("setLoading", true);
            var json = JSON.stringify(payload);
            api.post("proposals/" + payload.id, json, {
                headers: {
                    "Authorization": state.token.token_type + " " + state.token.access_token,
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
            }).then((response) => {
                commit("setProposal", response.data);
                commit("setErrorMessage", null);
                resolve(response);
            }).catch((error) => {
                commit("setErrorMessage", error.response.data);
                reject(error);
            }).finally(() => {
                commit("setLoading", false);
            });
        });
    },
    copyProposal({ commit, state }, payload) {
      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        api
          .post("proposals/" + payload.id + "/copy", null, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setProposal", response.data);
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    addFavorite({ commit, state }, payload) {
      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        api
          .post(
            "members/" +
              this.state.userInfo.subject +
              "/favorites/" +
              payload.resourceId,
            null,
            {
              headers: {
                Authorization:
                  state.token.token_type + " " + state.token.access_token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            commit("setMember", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    removeFavorite({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true);
        api
          .delete(
            "members/" +
              this.state.userInfo.subject +
              "/favorites/" +
              payload.resourceId,
            {
              headers: {
                Authorization:
                  state.token.token_type + " " + state.token.access_token,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            commit("setMember", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    /**
     * 
     * @param {*} param0 
     * @param {*} payload 
     * @returns 
     */
    createOrganization({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            commit("setLoading", true);
            api.post("organizations", payload, {
                headers: {
                    "Authorization": state.token.token_type + " " + state.token.access_token,
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
            }).then((response) => {
                commit("setOrganization", response.data);
                resolve(response);
            }).catch((error) => {
                commit("setErrorMessage", error.response.data);
                reject(error);
            }).finally(() => {
                commit("setLoading", false);
            });
        });
    },
    updateOrganization({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true);
        api
          .post("organizations/" + state.organization.id, payload, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setOrganization", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },

    /**
     * 
     * @param {*} param0 
     * @param {*} payload 
     * @returns 
     */
    fetchProject({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            commit("setLoading", true);
            api.get("projects/" + payload.id, {
                headers: {
                    Authorization: state.token.token_type + " " + state.token.access_token,
                    Accept: "application/json",
                },
            }).then((response) => {
                commit("setProject", response.data);
                resolve(response);
            }).catch((error) => {
                commit("setErrorMessage", error.response.data);
                reject(error);
            }).finally(() => {
                commit("setLoading", false);
            });
        });
    },

    /**
     * 
     * @param {*} param0 
     * @param {*} payload 
     * @returns 
     */
    createProject({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            commit("setLoading", true);
            api.post("projects", payload, {
                headers: {
                    "Authorization": state.token.token_type + " " + state.token.access_token,
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
            }).then((response) => {
                commit("setProject", response.data);
                commit("setErrorMessage", null);
                resolve(response);
            }).catch((error) => {
                reject(error);
            }).finally(() => {
                commit("setLoading", false);
            });
        });
    },

    /**
     * 
     * @param {*} param0 
     * @param {*} payload 
     * @returns 
     */
    updateProject({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            commit("setLoading", true);
            api.post("projects/" + payload.id, payload, {
                headers: {
                    "Authorization": state.token.token_type + " " + state.token.access_token,
                    "Content-Type": "application/json", 
                    "Accept": "application/json",
                }
            }).then((response) => {
                commit("setProject", response.data);
                commit("setErrorMessage", null);
                resolve(response);
            }).catch((error) => {
                commit("setErrorMessage", error.response.data);
                reject(error);
            }).finally(() => {
                commit("setLoading", false);
            });
        });
    },

    /**
     * 
     * @param {*} param0 
     * @param {*} payload 
     * @returns 
     */
    copyPrject({ commit, state }, payload) {
      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        api.post("projects/" + payload.id + "/copy", null, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setProject", response.data);
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },

    /**
     * 
     * @param {*} param0 
     * @param {*} payload 
     */
    deleteProject({ commit, state }, payload) {
      commit("setLoading", true);
      api.delete("projects/" + payload.id, {
          headers: {
            Authorization:
              state.token.token_type + " " + state.token.access_token,
          },
        })
        .then(() => {
          commit("setProject", null);
          commit("setErrorMessage", null);
        })
        .catch((error) => {
          commit("setErrorMessage", error.response.data);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
    updateProjectStage({ commit, state }, payload) {
        commit("setLoading", true);
        const json = JSON.stringify({
            stage: payload.stage,
        });
        api.post("projects/" + payload.id + "/stage", json, {
            headers: {
                Authorization: state.token.token_type + " " + state.token.access_token,
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        }).then((response) => {
            commit("setProject", response.data);
            commit("setErrorMessage", null);
        }).catch((error) => {
            commit("setErrorMessage", error.response.data);
        }).finally(() => {
            commit("setLoading", false);
        });
    },
    addProjectTeamMember({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            commit("setLoading", true);
            var json = JSON.stringify({
                memberId: payload.memberId
            });
            console.log(json);
            console.log(payload.id);
            api.post("projects/" + payload.id + "/team-members", json, {
                headers: {
                  Authorization:
                    state.token.token_type + " " + state.token.access_token,
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              })
                .then((response) => {
                    commit("setProject", response.data);
                    commit("setErrorMessage", null);
                    resolve(response);
                }).catch((error) => {
                    commit("setErrorMessage", error.response.data);
                    reject(error);
                }).finally(() => {
                    commit("setLoading", false);
                });
        });
    },
    /**
     * 
     * @param {*} param0 
     * @param {*} payload 
     * @returns 
     */
    fetchForum({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            commit("setLoading", true);
            api.get("forums/" + payload.id, {
                headers: {
                    "Authorization": state.token.token_type + " " + state.token.access_token,
                    "Accept": "application/json",
                },
            }).then((response) => {
                commit("setForum", response.data);
                resolve(response);
            }).catch((error) => {
                commit("setErrorMessage", error.response.data);
                reject(error);
            }).finally(() => {
                commit("setLoading", false);
            });
        });
    },
    createMember({ commit, state }, payload) {
      commit("setErrorMessage", null);
      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        api
          .post("members", payload, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    updateMember({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true);
        const json = JSON.stringify({
            version: payload.version,
          organizationId: payload.organizationId,
          type: payload.type,
          email: payload.email,
          familyName: payload.familyName,
          givenName: payload.givenName,
          preferredName: payload.preferredName,
          aboutMe: payload.aboutMe,
          title: payload.title,
          phone: payload.phone,
          linkedInProfile: payload.linkedInProfile,
          credlyProfile: payload.credlyProfile,
          cost: payload.cost,
          experienceLevel: payload.experienceLevel,
          countryCode: payload.countryCode,
        });
        console.log(json);
        api
          .post("members/" + payload.id, json, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setMember", response.data);
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    updateMemberAddress({ commit, state }, payload) {
      commit("setLoading", true);
      const json = JSON.stringify({
        version: payload.version,
        countryCode: payload.countryCode,
        stateCode: payload.stateCode,
        city: payload.city,
        street: payload.street,
        postalCode: payload.postalCode,
      });
      return new Promise((resolve, reject) => {
        api
          .put("members/" + payload.id + "/address", json, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setMember", response.data);
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    updateMemberSkills({ commit, state }, payload) {
      commit("setLoading", true);
      const json = JSON.stringify({
        version: payload.version,
        type: payload.type,
        skills: payload.skills,
      });
      return new Promise((resolve, reject) => {
        api
          .put("members/" + payload.id + "/skills", json, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setMember", response.data);
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    updateMemberIndustries({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true);
        const json = JSON.stringify({
            version: payload.version,
          industries: payload.industries,
        });
        api
          .put("members/" + payload.id + "/industries", json, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setMember", response.data);
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    updateMemberLanguages({ commit, state }, payload) {
      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        api
          .post("members/" + payload.id + "/languages", payload.languages, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setMember", response.data);
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    updateMemberRate({ commit, state }, payload) {
      commit("setLoading", true);
      const json = JSON.stringify({
        amount: payload.amount,
        currencyCode: payload.currencyCode,
        occurance: payload.occurance,
      });
      return new Promise((resolve, reject) => {
        api
          .put("members/" + payload.id + "/rate", json, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setMember", response.data);
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    updateMemberEligibility({ commit, state }, payload) {
      commit("setLoading", true);
      const json = JSON.stringify({
        citizen: payload.citizen,
        securityClearanceLevel: payload.securityClearanceLevel,
        securityClearanceNote: payload.securityClearanceNote
      });
      return new Promise((resolve, reject) => {
        api
          .put("members/" + payload.id + "/eligibility", json, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setMember", response.data);
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    updateMemberAvailability({ commit, state }, payload) {
      commit("setLoading", true);
      const json = JSON.stringify({
        availableOn: payload.availableOn,
      });
      return new Promise((resolve, reject) => {
        api
          .put("members/" + payload.id + "/availability", json, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setMember", response.data);
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    updateMemberSettings({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true);
        const json = JSON.stringify({
          currency: payload.currency,
          locale: payload.locale,
          language: payload.language,
          timezone: payload.timezone,
        });
        api
          .post("members/" + payload.id + "/settings", json, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setMember", response.data);
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    replaceMemberCertifications({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true);
        api
          .put(
            "members/" + payload.id + "/certifications",
            payload.certifications,
            {
              headers: {
                Authorization:
                  state.token.token_type + " " + state.token.access_token,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            commit("setMember", response.data);
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    addReferences({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true);
        api
          .post("members/" + payload.id + "/references", payload.references, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setMember", response.data);
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    searchMyTeam({ commit, state }, payload) {
      commit("setLoading", true);
      const json = JSON.stringify({
        keywords: payload.keywords.split(" "),
      });
      return new Promise((resolve, reject) => {
        api
          .post("members/" + payload.id + "/search/team", json, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setTeamMembers", response.data);
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    // removed listings in liu of a simple post to member to set listed
    // listMember({ commit, state }, payload) {
    //   //commit("setLoading", true);
    //   return new Promise((resolve, reject) => {
    //     api
    //       .post("listings/", payload, {
    //         headers: {
    //           Authorization:
    //             state.token.token_type + " " + state.token.access_token,
    //           "Content-Type": "application/json",
    //           Accept: "application/json",
    //         },
    //       })
    //       .then((response) => {
    //         commit("setErrorMessage", null);
    //         resolve(response);
    //       })
    //       .catch((error) => {
    //         commit("setErrorMessage", error.response.data);
    //         reject(error);
    //       });
    //      //.finally(() => {
    //      //   commit("setLoading", false);
    //      // });
    //   });
    // },
    fetchConnections({ commit, state }) {
        return new Promise((resolve, reject) => {
            commit("setErrorMessage", null);
            api.get("members/" + this.state.userInfo.subject + "/connections", {
                headers: {
                    Authorization: state.token.token_type + " " + state.token.access_token,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }).then((response) => {
                commit("setConnections", response.data);
                resolve(response);
            }).catch((error) => {
                commit("setErrorMessage", error.response.data);
                reject(error);
            });
        });
    },
    fetchInvitations({ commit, state }) {
        return new Promise((resolve, reject) => {
            commit("setErrorMessage", null);
            api.get("invitations", {
                headers: {
                    "Authorization": state.token.token_type + " " + state.token.access_token,
                    "Content-Type": "application/json", Accept: "application/json",
                },
            }).then((response) => {
                commit("setInvitations", response.data);
                resolve(response);
            }).catch((error) => {
                commit("setErrorMessage", error.response.data);
                reject(error);
            });
        });
    },

    //TODO need to look at this one
    listMemberWithoutUpdate({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit("setErrorMessage", null);
        api
          .get("members/" + payload.id + "/connections", {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setConnections", response.data);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },

    //TODO need to look at this one
    memberConnections({ commit, state }) {
      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        api
          .get("invitations?q=sent", {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setConnections", response.data);
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },

    // removed listing in liu of a simple delete on member that sets the listed attribute
    // delistMember({ commit, state }, payload) {
    //   return new Promise((resolve, reject) => {
    //     api
    //       .delete("listings/" + payload.id, {
    //         headers: {
    //           Authorization:
    //             state.token.token_type + " " + state.token.access_token,
    //           "Content-Type": "application/json",
    //         },
    //       })
    //       .then((response) => {
    //         commit("setErrorMessage", null);
    //         resolve(response);
    //       })
    //       .catch((error) => {
    //         commit("setErrorMessage", error.response.data);
    //         reject(error);
    //       });
    //   });
    // },
    deleteMember({ commit, state }, payload) {
      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        api
          .delete("members/" + payload.id, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setMember", null);
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },

    removeConnection({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            commit("setLoading", true);
            api.delete("members/" + this.state.userInfo.subject + "/connections/" + payload.whoId, {
                headers: {
                    Authorization: state.token.token_type + " " + state.token.access_token,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }).then((response) => {
                commit("setErrorMessage", null);
                resolve(response);
            }).catch((error) => {
                commit("setErrorMessage", error.response.data);
                reject(error);
            }).finally(() => {
                commit("setLoading", false);
            });
        });
    },

    sendInvitation({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            commit("setLoading", true);
            api.post("invitations/", { whoId: payload.whoId }, {
                headers: {
                    Authorization: state.token.token_type + " " + state.token.access_token,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }).then((response) => {
                commit("setErrorMessage", null);
                resolve(response);
            }).catch((error) => {
                commit("setErrorMessage", error.response.data);
                reject(error);
            }).finally(() => {
                commit("setLoading", false);
            });
        });
    },
    actionInvitation({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            commit("setLoading", true);
            const json = JSON.stringify({
                action: payload.action,
            });
            api.post("invitations/" + payload.id + "/action", json, {
                headers: {
                    Authorization: state.token.token_type + " " + state.token.access_token,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }).then((response) => {
                commit("setErrorMessage", null);
                resolve(response);
            }).catch((error) => {
                commit("setErrorMessage", error.response.data);
                reject(error);
            }).finally(() => {
                commit("setLoading", false);
            });
        });
    },
    uploadPicture({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            commit("setLoading", true);
            const formData = new FormData();
            formData.append("filename", payload.filename);
            formData.append("file", payload.file);
            api.post("members/" + payload.id + "/picture", formData, {
                headers: {
                    "Authorization": state.token.token_type + " " + state.token.access_token,
                    "Content-Type": "multipart/form-data",
                    "Accept": "application/json",
                },
            }).then((response) => {
                commit("setMember", response.data);
                commit("setErrorMessage", null);
                resolve(response);
            }).catch((error) => {
                commit("setErrorMessage", error.response.data);
                reject(error);
            }).finally(() => {
                commit("setLoading", false);
            });
        });
    },
    uploadResume({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true);
        const formData = new FormData();
        formData.append("filename", payload.filename);
        formData.append("file", payload.file);
        api
          .post("members/" + payload.id + "/resume", formData, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setMember", response.data);
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    deleteResume({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true);
        api
          .delete("members/" + payload.id + "/resume", {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/octet-stream",
            },
            responseType: "blob",
          })
          .then((response) => {
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    downloadResume({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        api
          .get("members/" + payload.id + "/resume", {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/octet-stream",
            },
            responseType: "blob",
          })
          .then((response) => {
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          });
      });
    },
    uploadFile({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true);
        const formData = new FormData();
        formData.append("filename", payload.file.name);
        formData.append("file", payload.file);
        formData.append("resourceId", payload.resourceId);
        formData.append("resourceType", payload.resourceType);
        api
          .post("files/", formData, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    fetchMyFiles({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true);
        api
          .get("files", {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setFiles", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    downloadFile({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            api.get("files/" + payload.id, {
                headers: {
                    Authorization: state.token.token_type + " " + state.token.access_token,
                    Accept: "application/octet-stream",
                },
                responseType: "blob",
            }).then((response) => {
                commit("setErrorMessage", null);
                resolve(response);
            }).catch((error) => {
                commit("setErrorMessage", error.response.data);
                reject(error);
            });
        });
    },
    deleteFile({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true);
        api
          .delete("files/" + payload.id, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject();
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    deleteProposal({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        api
          .delete("proposals/" + payload.id, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject();
          });
      });
    },
    fetchDashboards({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .get("analytics/dashboards", {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            commit("setDashboards", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchDashboard({ state }, payload) {
      return new Promise((resolve, reject) => {
        api
          .get("analytics/dashboards/" + payload.id, {
            headers: {
              Authorization:
                state.token.token_type + " " + state.token.access_token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchDashboardResults({ getters }, payload) {
      return api.get(
        "analytics/dashboards/" + payload.id + "/results",
        getters.getHeaders
      );
    },
    sendTerms({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true);
        api
          .post("members/" + payload.id + "/terms", payload.requestBody, {
            headers: {
              authorization:
                state.token.token_type + " " + state.token.access_token,
              "content-type": "application/json",
              accept: "application/json",
            },
          })
          .then((response) => {
            commit("setErrorMessage", null);
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    async sendVerificationCode({ commit }, payload) {
      const json = JSON.stringify({
        username: payload.username,
        clientId: process.env.VUE_APP_CLIENT_ID
      });
      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        oauth.post("/oauth2/password/forgot", json, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },
    async resetPassword({ commit }, payload) {
      const json = JSON.stringify({
        username: payload.username,
        confirmationCode: payload.confirmationCode,
        password: payload.password,
        clientId: process.env.VUE_APP_CLIENT_ID,
        clientSecret: process.env.VUE_APP_CLIENT_SECRET,
      });

      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        oauth.post("/oauth2/password/confirm", json, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            commit("setErrorMessage", error.response.data);
            reject(error);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      });
    },

    /**
     * 
     * @param {*} param0 
     * @param {*} payload 
     * @returns 
     */
    post({ state }, payload) {
        return new Promise((resolve, reject) => {
            api.post(payload.endpoint, payload.form,
                {
                    headers: {
                        "Authorization": state.token.token_type + " " + state.token.access_token,
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                    },
                }
            ).then((response) => {
                resolve(response);
            }).catch((error) => {
                if (401 == error.response.status) {
                    if (payload.path) {
                        router.push('/signin?redirect=' + payload.path);
                    } else {
                        router.push('/signin');
                    }
                } else {
                    reject(error);
                }
            });
        });
    },

    /**
     * 
     * @param {*} param0 
     * @param {*} payload 
     * @returns 
     */
    delete({ state }, payload) {
        return new Promise((resolve, reject) => {
            api.delete(payload.endpoint,
                {
                    headers: {
                        "Authorization": state.token.token_type + " " + state.token.access_token,
                        "Accept": "application/json",
                    },
                }
            ).then((response) => {
                resolve(response);
            }).catch((error) => {
                if (401 == error.response.status) {
                    if (payload.path) {
                        router.push('/signin?redirect=' + payload.path);
                    } else {
                        router.push('/signin');
                    }
                } else {
                    reject(error);
                }
            });
        });
    },

    /**
     * 
     * @param {*} param0 
     * @param {*} payload 
     * @returns 
     */
    fetch({ state }, payload) {
        return new Promise((resolve, reject) => {
            api.get(payload.endpoint,
                {
                    headers: {
                        "Authorization": state.token.token_type + " " + state.token.access_token,
                        "Accept": "application/json",
                    },
                }
            ).then((response) => {
                resolve(response);
            }).catch((error) => {
                if (401 == error.response.status) {
                    if (payload.path) {
                        router.push('/signin?redirect=' + payload.path);
                    } else {
                        router.push('/signin');
                    }
                } else {
                    reject(error);
                }
            });
        });
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload
     * @returns
     */
    stream({ state }, payload) {
        return new Promise((resolve, reject) => {
            api.get(payload.endpoint,
                {
                    headers: {
                        "Authorization": state.token.token_type + " " + state.token.access_token,
                        "Accept": "application/octet-stream",
                    },
                    responseType: "blob",
                }
            ).then((response) => {
                resolve(response);
            }).catch((error) => {
                if (401 == error.response.status) {
                    if (payload.path) {
                        router.push('/signin?redirect=' + payload.path);
                    } else {
                        router.push('/signin');
                    }
                } else {
                    reject(error);
                }
            });
        });
    },

    sendPageView({ getters }, payload) {
      return new Promise(() => {
        const json = JSON.stringify({ page: payload.fullPath });
        api.post("/events/page", json, getters.getHeaders).catch((error) => {
          console.error(error);
        });
      });
    },
    sendMemberView({ getters }, payload) {
      return new Promise(() => {
        const json = JSON.stringify({ memberId: payload.id });
        api.post("/events/member", json, getters.getHeaders).catch((error) => {
          console.error(error);
        });
      });
    },
    sendProjectView({ getters }, payload) {
      return new Promise(() => {
        const json = JSON.stringify({ projectId: payload.id });
        api.post("events/project", json, getters.getHeaders).catch((error) => {
          console.error(error);
        });
      });
    },
  },

  mutations: {
    initializeStore(state) {
      if (localStorage.getItem("store")) {
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem("store")))
        );
      }
    },
    resetStore(state) {
      state.session = null;
      state.authenticated = false;
      state.authorized = false;
      state.metadata = null;
      state.loading = false;
      state.data = null;
      state.project = null;
      state.member = null;
      state.token = {};
      state.userInfo = {};
      state.notifications = [];
      state.profile = {};
      state.recent = null;
      state.favorites = null;
      state.connections = null;
      state.teamMembers = [];
      state.organizationMembers = [];
      state.openRoles = null;
      state.consultingPartners = [];
      state.openRoles = [];
      state.organization = null;
      state.projects = [];
      state.files = null;
      state.projectMetrics = null;
      state.dashboards = null;
      state.success = false;
      state.error = false;
      state.errorMessage = null;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setSession(state, session) {
      state.session = session;
    },
    setToken(state, token) {
      state.token = token;
    },
    setAuthenticated(state, authenticated) {
      state.authenticated = authenticated;
    },
    setAuthorized(state, authorized) {
      state.authorized = authorized;
    },
    setUserInfo: (state, userInfo) => (state.userInfo = userInfo),
    setData: (state, data) => {
      state.data = data;
    },
    setRegistration: (state, registration) => {
      state.registration = registration;
    },
    setNotifications: (state, notifications) =>
      (state.notifications = notifications),
    setMetadata: (state, metadata) => (state.metadata = metadata),
    setMember: (state, member) => (state.member = member),
    setTeamMembers: (state, teamMembers) => (state.teamMembers = teamMembers),
    setOrganizationResourceManagers: (state, organizationResourceManagers) =>
      (state.organizationResourceManagers = organizationResourceManagers),
    setOrganizationMembers: (state, organizationMembers) => {
      state.organizationMembers = organizationMembers;
    },
    setProjects: (state, projects) => {
      state.projects = projects;
    },
    setForum: (state, forum) => {
      state.forum = forum;
    },
    setFiles: (state, files) => {
      state.files = files;
    },
    setOpenRoles: (state, openRoles) => {
      state.openRoles = openRoles;
    },
    setProjectMetrics: (state, projectMetrics) => {
      state.projectMetrics = projectMetrics;
    },
    setFavorites: (state, favorites) => {
      state.favorites = favorites;
    },
    setRecent: (state, recent) => {
      state.recent = recent;
    },
    setSuggested: (state, suggested) => {
      state.suggested = suggested;
    },
    setConnections: (state, connections) => {
      state.connections = connections;
    },
    setInvitations: (state, invitations) => {
      state.invitations = invitations;
    },
    setDashboards: (state, dashboards) => {
      state.dashboards = dashboards;
    },
    setProject: (state, project) => {
      state.project = project;
    },
    setProposals: (state, proposals) => {
      state.proposals = proposals;
    },
    setProposal: (state, proposal) => {
      state.proposal = proposal;
    },
    setConsultingPartners: (state, consultingPartners) => {
      state.consultingPartners = consultingPartners;
    },
    setSoftwareVendors: (state, softwareVendors) => {
      state.softwareVendors = softwareVendors;
    },
    setCustomers: (state, customers) => {
      state.customers = customers;
    },
    setCandidate: (state, candidate) => {
      state.candidate = candidate;
    },
    setOrganization: (state, organization) => {
      state.organization = organization;
    },
    setSuccess: (state, success) => {
      state.success = success;
    },
    setError: (state, error) => {
      state.error = error;
    },
    setErrorMessage: (state, errorMessage) => {
      state.errorMessage = errorMessage;
    },
  },
});

store.subscribe((dispatch, state) => {
  localStorage.setItem("store", JSON.stringify(state));
});

export default store;
