<template>
   <v-app-bar v-if="authorized" app flat :color="this.$vuetify.theme.dark ? '#000000' : 'transparent'">
    <v-app-bar-nav-icon @click.stop="updateDrawer"></v-app-bar-nav-icon>
    <v-toolbar-title>
      <v-img v-if="this.$vuetify.theme.dark" height="20" width="120" :src="require('@/assets/logo-dark.png')"></v-img>
       <v-img v-else height="20" width="120" :src="require('@/assets/logo-light.png')"></v-img>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn icon to="/network">
      <v-badge v-if="invitations > 0" overlap color="green" :content="invitations">
      <v-icon>mdi-account-network</v-icon>
    </v-badge>
      <v-icon v-else>mdi-account-network</v-icon>
    </v-btn>
    <v-menu
      v-model="showMenu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
      transition="slide-y-transition"
      bottom
      rounded>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon v-if="notifications?.length === 0">mdi-inbox</v-icon>
          <v-badge v-else overlap color="green" :content="getUnreadNotificationCount()">
            <v-icon>mdi-inbox-full</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-card class="pr-1 border-primary" style="width: 400px; max-width: 100%">
        <v-list>
          <v-list-item>
            <v-avatar color="primary" class="white--text">
              {{
                (userInfo.given_name != null
                  ? userInfo.given_name.substring(0, 1)
                  : null
                ).concat(
                  userInfo.family_name != null
                    ? userInfo.family_name.substring(0, 1)
                    : null
                )
              }}
            </v-avatar>
            <v-list-item-content class="pl-4">
              <v-list-item-title
                >{{ userInfo.given_name }} {{ userInfo.family_name }}</v-list-item-title
              >
              <v-list-item-subtitle>{{
                userInfo.organization_name
              }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-chip small class="green white--text">
                {{ getUnreadNotificationCount() }}
              </v-chip>
              <div class="caption">{{$t('unreadMessages')}}</div>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-list class="pa-0">
          <v-list-item v-if="notifications?.length === 0">
            <div class="warning--text font-weight-bold pt-4 pb-10">
              {{$t('noNotification')}}
            </div>
          </v-list-item>
          <v-container class="pa-0 message-container">
            <v-list-item
              v-for="(item, index) of notifications"
              :key="index"
              class="d-flex align-stretch"
              style="border-bottom: 1px solid #9e9e9e"
            >
              <div
                class="text-right pt-1 pr-2 flex-grow-0 w-90"
                style="border-right: 1px solid #9e9e9e"
              >
                <div class="caption pt-2">
                  <div>
                    {{
                      new Date(item?.sentOn).toLocaleDateString(userInfo.locale, {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })
                    }}
                  </div>
                  <div>
                    {{
                      new Date(item?.sentOn)
                        .toLocaleDateString(userInfo.locale, {
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })
                        .split(", ")[1]
                    }}
                  </div>
                </div>
              </div>
              <div class="pa-2 flex-grow-0 message-content">
                <div
                  class="text-truncate"
                  :title="item?.subject"
                  :class="{ 'font-weight-bold': !item?.read }"
                >
                  {{ item?.subject }}
                </div>
                <div class="text-wrap2 caption" :title="item?.message">
                  {{ item?.message }}
                </div>
                <div class="caption pt-1">
                  <span class="pr-1 grey--text">{{$t('from')}}</span>
                  <v-chip small>
                    {{ item?.from?.displayName }}
                  </v-chip>
                </div>
              </div>
            </v-list-item>
            <v-list-item class="justify-end">
              <v-btn text to="/inbox" @click="toggleMenu(false)" v-if="notifications?.length > 0"> {{$t('more')}} </v-btn>
            </v-list-item>
          </v-container>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="toggleMenu(false)"> {{$t('cancel')}} </v-btn>
          <v-btn color="primary" text @click="toggleMenu(false)" to="/inbox">
            {{$t('gotoInbox')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-btn icon @click="toggleTheme()">
      <v-icon>mdi-theme-light-dark</v-icon>
    </v-btn>
    <v-btn icon to="/settings">
      <v-icon>mdi-cog</v-icon>
    </v-btn>

    <v-menu
      v-if="showPersonaSwitcher"
      :close-on-content-click="true"
      :nudge-width="200"
      offset-y
      transition="slide-y-transition"
      bottom
      rounded>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" class="mx-2">
          <v-avatar color="primary" class="white--text">
            {{
              (userInfo.given_name != null
                ? userInfo.given_name.substring(0, 1)
                : null
              ).concat(
                userInfo.family_name != null ? userInfo.family_name.substring(0, 1) : null
              )
            }}
          </v-avatar>
        </v-btn>
      </template>
      <v-card class="pr-1 border-primary" style="width: 400px; max-width: 100%">
        <v-list class="pa-0">
          <v-list-item>
            <span class="caption">
              {{$t('signInAs')}}
            </span>
            <v-spacer></v-spacer>
            <v-chip class="primary text-right">
              {{currentUserType}}
            </v-chip>
          </v-list-item>
          <v-list-item v-for="(item, index) of userTypes" :key="index" 
            class="hover-chip border-top-primary py-2 px-4 d-block"
            @click="switchUser(item)">
            <div class="subtitle-1">
              {{item.name}}
            </div>
            <div class="grey--text caption">
              {{$t('description')}}: {{item.description}}
            </div>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <v-btn icon class="mx-2" v-else>
      <v-avatar color="primary" class="white--text">
        {{
          (userInfo.given_name != null
            ? userInfo.given_name.substring(0, 1)
            : null
          ).concat(
            userInfo.family_name != null ? userInfo.family_name.substring(0, 1) : null
          )
        }}
      </v-avatar>
    </v-btn>
  </v-app-bar>
</template>
<script>
export default {
    name: "Appbar",
    data() {
        return {
            showMenu: false,
        };
    },
    computed: {
        showPersonaSwitcher() {
            return this.$store.state.metadata.showPersonaSwitcher;
        },
        authorized() {
            return this.$store.state.authorized;
        },
        companyName() {
            return process.env.VUE_APP_COMPANY_NAME;
        },
        userInfo() {
            return this.$store.state.userInfo;
        },
        notifications() {
            return this.$store.state?.notifications?.records?.slice(0, 5);
        },
        invitations() {
            return this.$store.state.invitations?.size;
        },
        showSignIn() {
            if (this.$route.path === '/terms' || this.$route.path === "/signin" || this.$route.path === "/reset-password") {
                return false;
            } else {
                return true;
            }
        },
        color() {
            return this.$vuetify.theme.dark ? '#000000' : '#263238';
        },
        userTypes() {
            return this.$store.state.metadata?.userTypes;
        },
        currentUserType() {
            const user = this.userTypes?.find(user => user.type === this.$store.state.userInfo.type);
            if (user) {
                return user.name;
            } else {
                return '';
            }
        },
    },
    methods: {
        toggleTheme() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            this.$cookies.set("app.darkMode", this.$vuetify.theme.dark);
        },
        updateDrawer() {
            this.$emit("update");
        },
        toggleMenu(status) {
            if (status !== undefined) {
                this.showMenu = status;
            } else {
                this.showMenu = !this.showMenu;
            }
        },
        getUnreadNotificationCount() {
            if (this.notifications?.length) {
                return this.notifications?.filter((noti) => noti.read === false)?.length;
            }
            return 0;
        },
        switchUser(user) {
            if (user.username && user.username === this.$store.state.userInfo.username) return;
            let userId = this.userTypes?.find(u => u.type === user.type).userId;
            if (userId) {
                this.$router.push("/signin").then(() => {
                    this.signInAs(userId);
                }).catch((error) => {
                    console.log(error);
                });
            } else {
                this.$router.push("/signin").then(() => {
                    this.refreshToken();
                }).catch((error) => {
                    console.log(error);
                });
            }
        },
        refreshToken() {
            this.$store.dispatch('refreshToken')
                .then(() => {
                    this.$router.push("/home");
                }).catch((error) => {
                    console.log(error);
                })
        },
        signInAs(userId) {
            this.$store.dispatch('signInAs', {
                subject: userId
            }).then(() => {
                this.$router.push("/home");
            }).catch((error) => {
                console.log(error);
            })
        }
    },
};
</script>

<style scoped>
.text {
  font-weight: 300;
  font-size: 2em;
  line-height: 1em;
}
.text-wrap2 {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  overflow: hidden;
}
.border-primary {
  border: 1px solid #2196f3;
}
.border-top-primary {
  border-top: 1px solid #2196f3;
}
.w-90 {
  width: 90px;
}
.message-container {
  max-height: 400px;
  overflow-y: auto;
}
.message-content {
  width: calc(100% - 90px);
}
.hover-chip:hover {
  cursor: pointer;
}
.hover-chip:hover > div:first-child {
  color: #2196f3;
}
</style>

<i18n>
{
  "en": {
    "company" : "Who we are",
    "value" : "Hiring Talent",
    "services" : "Recruiting Talent",
    "about" : "About Us",
    "ourStory" : "Our Story",
    "leadership" : "Leadership",
    "executiveTeam" : "Executive Team",
    "news" : "News and press releases",
    "newsroom" : "The latest from our newsroom",
    "clients" : "Our Clients",
    "results" : "Their results",
    "diversity" : "Diversity, Equality & Inclusion",
    "empowering" : "Empowering our people",
    "sustainability" : "Sustainability | ESG",
    "approach" : "Our Committment and Approach",
    "careers" : "Carrers",
    "certifiedTalent" : "Certified talent",
    "searchForWork" : "Search for work",
    "joinUs" : "Join Connxions",
    "signIn" : "Sign In",
    "signUp" : "Sign Up",
    "freeTrial" : "Sign up for a free trial",
    "hireTalent" : "Hire talent",
    "searchTalent" : "Search Talent",
    "staffJob" : "Staff my jobs",
    "postJob" : "Post a job",
    "unreadMessages": "Unread Messages",
    "noNotification": "No notifications found.",
    "from": "from",
    "more": "more...",
    "cancel": "Cancel",
    "gotoInbox": "Go to Inbox",
    "signInAs": "Sign in as",
    "description": "Description"
  }
}
</i18n>